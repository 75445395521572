@charset "UTF-8";
.main-filter-box {
  width: 100%;
  position: relative; }

.main-filter-box-settings {
  width: 100%;
  background: #e3e1e1;
  margin: 0 0 0 0; }

.main-filter-box-settings-inner {
  margin: 0 -10px; }

.main-filter-item {
  float: left;
  padding: 0 10px;
  width: 100%;
  margin: 0 0 10px 0; }

.main-filter-item .main-filter-item-controls {
  width: 100%;
  background: #fff;
  font-size: 0;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  white-space: nowrap;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center; }

.main-filter-item .main-filter-item-controls:before {
  content: "";
  width: 15px;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  background: #fff; }

.main-filter-item .main-filter-item-controls .main-filter-item-controls-arrow {
  width: 40px;
  height: 40px;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  padding-left: 10px; }

.main-filter-item .main-filter-item-controls .main-filter-item-controls-arrow:before {
  content: "\e915";
  color: #C16D53;
  font-size: 0.4rem;
  font-size: 10px;
  line-height: 45px;
  margin-right: 10px; }

.main-filter-item .main-filter-item-controls .main-filter-item-controls-text {
  display: block;
  font-weight: bold;
  font-size: 0.8rem;
  vertical-align: middle;
  width: 77%; }

.main-filter-item.open .main-filter-item-controls:before {
  background: #f6f3ed; }

@media (min-width: 769px) {
  .main-filter-item {
    width: 50%; } }

@media (min-width: 992px) {
  .main-filter-item {
    width: 33.33%; } }

@media (min-width: 1200px) {
  .main-filter-item {
    width: 16.66%;
    margin: 0; } }

.main-filter-items ul {
  font-size: 0; }

.main-filter-items ul li {
  display: inline-block;
  width: 100%; }

@media (min-width: 545px) {
  .main-filter-items ul li {
    width: 50%; } }

@media (min-width: 769px) {
  .main-filter-items ul li {
    width: 33.33%; } }

@media (min-width: 992px) {
  .main-filter-items ul li {
    width: 25%; } }

@media (min-width: 1200px) {
  .main-filter-items ul li {
    width: 20%; } }

.product-filter-count {
  color: #b1afb1;
  display: inline-block;
  vertical-align: top;
  margin: 0 0 0 3px 0; }

.product-filter-count:before {
  content: "("; }

.product-filter-count:after {
  content: ")"; }

.product-filter {
  width: 100%;
  float: left;
  margin-right: -100px;
  padding-right: 0; }

.main-filter-clear-box {
  float: right;
  width: 100px;
  position: relative;
  z-index: 22;
  padding: 0 10px 0 0; }

.main-filter-clear {
  width: auto;
  height: 40px;
  background: #C16D53;
  display: inline-block;
  text-align: center;
  text-decoration: none !important;
  -moz-transition: all ease-in-out 100ms;
  -o-transition: all ease-in-out 100ms;
  -webkit-transition: all ease-in-out 100ms;
  transition: all ease-in-out 100ms;
  padding: 0 8px;
  color: #fff; }

.main-filter-clear:before {
  content: "\e902";
  line-height: 40px;
  color: #fff;
  font-size: 0.7rem;
  -moz-transition: all ease-in-out 100ms;
  -o-transition: all ease-in-out 100ms;
  -webkit-transition: all ease-in-out 100ms;
  transition: all ease-in-out 100ms;
  margin: 0 5px 0 0; }

.main-filter-clear:focus, .main-filter-clear:hover {
  background: #c20510;
  color: #fff; }

.main-filter-clear:focus:before, .main-filter-clear:hover:before {
  color: #fff; }

.main-filter-box-set {
  width: 100%; }

.main-filter-box-set .main-filter-box-set-inner {
  margin: 0 -5px; }

.main-filter-box-set-item-box {
  float: left;
  margin-top: 10px;
  padding: 0 5px; }

.main-filter-box-set-item-box .main-filter-box-set-item {
  background: #f6f3ed;
  padding: 10px;
  font-size: 0; }

.main-filter-box-set-item-box .main-filter-box-set-item .main-filter-box-set-item-name {
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  border-radius: 5px !important;
  line-height: 0.7rem; }

.main-filter-box-set-item-box .main-filter-box-set-item .main-filter-box-set-item-remove {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 0 10px;
  text-decoration: none !important;
  cursor: pointer; }

.main-filter-box-set-item-box .main-filter-box-set-item .main-filter-box-set-item-remove:before {
  content: "\e914";
  color: #C16D53;
  font-size: 9px;
  position: relative;
  top: 1px; }

.main-filter-item-content {
  position: absolute;
  width: 100%;
  background: #f6f3ed;
  left: 0;
  display: none;
  z-index: 666; }

.main-filter-item-content .main-filter-item-content-inner {
  padding: 20px; }

.main-filter-item-content input[type="checkbox"] {
  display: none; }

.main-filter-item-content input[type="checkbox"] + label {
  padding: 0 0 0 35px;
  position: relative; }

.main-filter-item-content input[type="checkbox"] + label:before {
  content: "";
  width: 20px;
  height: 20px;
  border: 1px solid #e3e1e1;
  background: #fff;
  position: absolute;
  left: 0;
  top: 0px; }

.main-filter-item-content input[type="checkbox"] + label:after {
  content: "\e911";
  color: #C16D53;
  font-size: 0.6rem;
  position: absolute;
  left: 4px;
  top: 4px;
  display: none; }

.main-filter-item-content input[type="checkbox"]:checked + label {
  color: #C16D53; }

.main-filter-item-content input[type="checkbox"]:checked + label:after {
  display: block; }

.main-filter-item-content-singl-checkbox-list {
  width: 100%; }

.main-filter-item-content-singl-checkbox-list ul {
  margin: 0 -5px;
  padding: 0;
  list-style: none;
  font-size: 0; }

.main-filter-item-content-singl-checkbox-list ul li {
  display: inline-block;
  font-size: 0.7rem;
  width: 20%;
  padding: 0 5px; }

.main-filter-item-content-singl-checkbox-list ul li:before {
  display: none; }

.main-filter-item-content-range {
  font-size: 0; }

.main-filter-item-content-range .main-filter-content-renge-value {
  font-size: 0.7rem;
  font-weight: bold;
  float: left;
  width: 100px; }

.main-filter-item-content-range .main-filter-content-renge-option {
  float: left;
  width: 100%;
  min-height: 1px;
  margin: 0 -100px 0 -100px;
  padding: 0 125px 0 125px; }

.main-filter-item-content-inner .no-ui-slider {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: space-between;
  -moz-box-pack: space-between;
  -ms-flex-pack: space-between;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }

@media (min-width: 545px) {
  .main-filter-item-content-inner .no-ui-slider {
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -moz-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap; } }

.main-filter-item-content-inner .noUi-target {
  height: auto;
  background: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border: none;
  margin: 0;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  border-radius: 0px; }

.main-filter-item-content-inner .noUi-horizontal .noUi-handle {
  width: 15px;
  height: 15px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  background: #C16D53;
  top: -6px;
  left: -10px;
  cursor: pointer; }

.main-filter-item-content-inner .noUi-horizontal .noUi-handle {
  border: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none; }

.main-filter-item-content-inner .noUi-horizontal .noUi-handle:before, .main-filter-item-content-inner .noUi-horizontal .noUi-handle:after {
  display: none; }

.main-filter-item-content-inner .noUi-connect {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  background: #ebe5d9; }

.main-filter-item-content-inner .noUi-base {
  height: 6px;
  background: #fff;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  border: 1px solid #e3e1e1;
  max-width: 90%;
  margin: 15px 5%;
  -webkit-align-self: center;
  -moz-align-self: center;
  -ms-align-self: center;
  -webkit-align-self: center;
  align-self: center;
  -webkit-box-ordinal-group: 2;
  -moz-box-ordinal-group: 2;
  -ms-box-ordinal-group: 2;
  -ms-flex-order: 2;
  -webkit-order: 2;
  -moz-order: 2;
  -ms-order: 2;
  order: 2;
  cursor: pointer; }

@media (min-width: 545px) {
  .main-filter-item-content-inner .noUi-base {
    max-width: 80%;
    margin: 0; } }

.main-filter-item-content-inner .upper {
  width: 50%;
  text-align: right; }

@media (min-width: 545px) {
  .main-filter-item-content-inner .upper {
    text-align: right;
    -webkit-box-ordinal-group: 3;
    -moz-box-ordinal-group: 3;
    -ms-box-ordinal-group: 3;
    -ms-flex-order: 3;
    -webkit-order: 3;
    -moz-order: 3;
    -ms-order: 3;
    order: 3;
    min-width: 110px;
    width: auto; } }

.main-filter-item-content-inner .lower {
  width: 50%; }

@media (min-width: 545px) {
  .main-filter-item-content-inner .lower {
    -webkit-box-ordinal-group: 1;
    -moz-box-ordinal-group: 1;
    -ms-box-ordinal-group: 1;
    -ms-flex-order: 1;
    -webkit-order: 1;
    -moz-order: 1;
    -ms-order: 1;
    order: 1;
    min-width: 110px;
    width: auto; } }

.main-filter-item-content ul {
  margin: 0;
  padding: 0;
  list-style: none; }

.main-filter-item-content ul li {
  padding: 0;
  font-size: 0.8rem; }

.main-filter-item-content ul li:before {
  display: none; }

.main-filter-item-content-categories {
  margin: 0 -10px; }

.main-filter-item-content-categories .main-filter-item-contet-category {
  width: 20%;
  float: left;
  padding: 0 10px; }

.main-filter-item-content-categories .main-filter-item-content-category-title {
  color: #b1afb1;
  font-weight: bold;
  padding: 0 0 25px 0;
  font-size: 0.8rem; }

.main-filter-item.open .main-filter-item-controls {
  background: #f6f3ed;
  -webkit-box-shadow: 0 0 3px 0 #eaeaea;
  -moz-box-shadow: 0 0 3px 0 #eaeaea;
  box-shadow: 0 0 3px 0 #eaeaea;
  position: relative;
  z-index: 33; }

.main-filter-item.open .main-filter-item-controls:after {
  content: "";
  width: 100%;
  height: 3px;
  background: #f6f3ed;
  position: absolute;
  left: 0;
  bottom: -2px; }

.main-filter-item.open .main-filter-item-content {
  display: block;
  -webkit-box-shadow: 0 0 3px 0 #eaeaea;
  -moz-box-shadow: 0 0 3px 0 #eaeaea;
  box-shadow: 0 0 3px 0 #eaeaea; }

.filter-label {
  display: block;
  font-size: 14px;
  color: #303626;
  width: 90%;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis; }

.filter-label.filter-active {
  color: #C16D53; }

.more-filters {
  max-height: 500px;
  overflow: auto;
  top: 340px;
  padding: 0 0 15px 0; }

@media (min-width: 769px) {
  .more-filters {
    top: 190px; } }

@media (min-width: 992px) {
  .more-filters {
    top: 140px; } }

@media (min-width: 1200px) {
  .more-filters {
    top: 90px; } }

.more-filters.open {
  display: block; }

.more-filters .main-filter-item {
  overflow: hidden;
  margin: 0 0 20px 0; }

.more-filters .main-filter-item .main-filter-item-controls {
  background: none;
  cursor: default;
  white-space: normal; }

.more-filters .main-filter-item .main-filter-item-controls:before {
  display: none; }

.more-filters .main-filter-item .main-filter-item-controls-arrow {
  display: none; }

.more-filters .main-filter-item .main-filter-item-controls .filter-label {
  color: #b1afb1;
  font-weight: bold;
  padding: 0 0 25px 0;
  font-size: 11px;
  font-size: 0.78571rem; }

.more-filters .main-filter-item .main-filter-item-controls .filter-label.filter-active {
  color: #C16D53; }

.more-filters .main-filter-item .main-filter-item-content {
  display: block;
  position: relative; }

.more-filters .main-filter-item .main-filter-item-content-inner {
  height: auto !important;
  padding: 0; }

.more-filters .main-filter-item .main-filter-item-content-inner table td {
  padding: 0; }

.more-filters .main-filter-item .main-filter-item-content-inner .noUi-target {
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }

.more-filters .main-filter-item .main-filter-item-content-inner .noUi-target .noUi-base {
  width: 90%;
  margin: 15px 5%;
  -webkit-box-ordinal-group: 3;
  -moz-box-ordinal-group: 3;
  -ms-box-ordinal-group: 3;
  -ms-flex-order: 3;
  -webkit-order: 3;
  -moz-order: 3;
  -ms-order: 3;
  order: 3; }

.more-filters .main-filter-item .main-filter-item-content-inner .lower {
  width: 50%;
  min-width: 50%;
  -webkit-box-ordinal-group: 1;
  -moz-box-ordinal-group: 1;
  -ms-box-ordinal-group: 1;
  -ms-flex-order: 1;
  -webkit-order: 1;
  -moz-order: 1;
  -ms-order: 1;
  order: 1; }

.more-filters .main-filter-item .main-filter-item-content-inner .upper {
  width: 50%;
  min-width: 50%;
  -webkit-box-ordinal-group: 2;
  -moz-box-ordinal-group: 2;
  -ms-box-ordinal-group: 2;
  -ms-flex-order: 2;
  -webkit-order: 2;
  -moz-order: 2;
  -ms-order: 2;
  order: 2; }

.more-filters ul li {
  display: block;
  width: 100%; }

.more-filters .main-filter-item {
  width: 33.33%; }

@media (min-width: 769px) {
  .more-filters .main-filter-item {
    width: 33.33%; } }

@media (min-width: 992px) {
  .more-filters .main-filter-item {
    width: 25%; } }

@media (min-width: 1200px) {
  .more-filters .main-filter-item {
    width: 19%; } }

.more-filters-btn.filter-active .main-filter-item-controls-text {
  color: #C16D53; }

body .product-select-filters {
  border: 0;
  padding: 0; }
  body .product-select-filters .main-filter-item {
    border: 2px solid #f5f5f5;
    margin: 0;
    min-width: auto;
    width: 50%;
    flex-basis: 50%;
    max-width: 50%;
    padding: 0; }
    @media (min-width: 769px) {
      body .product-select-filters .main-filter-item {
        width: 25%;
        flex-basis: 25%;
        max-width: 25%; } }
    body .product-select-filters .main-filter-item + .main-filter-item {
      border-left: 0; }
    body .product-select-filters .main-filter-item:nth-of-type(3) {
      border-left: 2px solid #f5f5f5 !important;
      border-top: 0; }
    @media (min-width: 769px) {
      body .product-select-filters .main-filter-item:first-of-type {
        border-radius: 5px 0 0 5px; } }
    body .product-select-filters .main-filter-item .main-filter-item-controls-arrow {
      padding-left: 20px; }
    body .product-select-filters .main-filter-item .main-filter-item-controls-text {
      padding-left: 10px; }
  @media (min-width: 769px) {
    body .product-select-filters .main-filter-item:nth-of-type(3) {
      border-left: 0 !important;
      border-top: 2px solid #f5f5f5 !important; } }
  body .product-select-filters .main-filter-box-settings-inner {
    margin: 0; }
  body .product-select-filters .main-filter-box-set {
    padding-top: 20px; }
  body .product-select-filters .main-filter-item .main-filter-item-controls {
    height: 50px; }
  body .product-select-filters .main-filter-clear-box {
    position: absolute;
    width: 50%;
    right: 0;
    top: 62px;
    padding: 0; }
    @media (min-width: 769px) {
      body .product-select-filters .main-filter-clear-box {
        width: 25%;
        top: 10px; } }
    body .product-select-filters .main-filter-clear-box .filter-reset {
      width: 100%;
      position: static;
      border-radius: 0;
      border: 2px solid #f5f5f5;
      border-left: 0;
      background: #FFFFFF;
      height: 54px;
      outline: 0 !important; }
      @media (min-width: 769px) {
        body .product-select-filters .main-filter-clear-box .filter-reset {
          border-radius: 0 5px 5px 0; } }
      body .product-select-filters .main-filter-clear-box .filter-reset:before {
        font-size: 14px;
        position: absolute;
        left: 24px; }
      body .product-select-filters .main-filter-clear-box .filter-reset:after {
        content: "Zrušit filtry";
        position: absolute;
        left: 50px;
        color: #C16D53;
        font-size: 14px;
        font-weight: 700;
        font-family: "Source Sans Pro", sans-serif !important;
        display: inline-block;
        top: 20px; }

body .category-filter .selectric {
  border-color: #f5f5f5; }

body .product-filter-bottom .product-appearance-filter .pux-infinite-repeater-result-count-wrapper {
  position: absolute;
  left: 0;
  top: 14px; }
