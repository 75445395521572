
//main

$base-font: "soleil",sans-serif;
$base-color: #303626;
$base-white: #FFFFFF;

$color-orange: #ffa800;
$color-orange-dark: #f59e00;
$color-green: #658d1b;
$color-light-grey: #F7F7F7;
$color-grey: #f5f5f5;
$color-dark-grey: #ebebeb;
$color-available: #84bd00;
$color-new-green: #62bb46;

$scale-xxl: 1;
$scale-xl: 0.8;
$scale-lg: 0.7;
$scale-md: 0.6;
$scale-sm: 0.5;
$scale-xs: 0.4;
$scale-xxs: 0.4;

$font-size: 16px;
$font-size-min: 14px;
$line-height: 24px;
$line-height-min: 21px;
$min-padding: 10px;
$min-margin: 10px;

//scale ratios
$scale-base-size: 10px;

$scale-ratio-xxs: 0.4;
$scale-ratio-xs: 0.4;
$scale-ratio-sm: 0.5;
$scale-ratio-md: 0.6;
$scale-ratio-lg:  0.7;
$scale-ratio-xl: 0.8;
$scale-ratio-xxl: 1;
$scale-ratio-2K: 1.05;
$scale-ratio-4K: 1.5;

// redesign 2022

$base-font: 'Source Sans Pro', sans-serif;
$title-font: 'Prata';
$color-orange: #C16D53;
$color-orange-dark: #AA496C;
$color-new-green: #4E8F32;
$color-new-green-dark: #3B8760;
$color-new-green-light: #D8E5D6;
$color-light-grey: #EFEFEE;
$border-green-color: #2E716A;
$color-available: $color-new-green;
$color-blue: #2E716A;

$category-article: #AA496C;
$category-herbal: #FFBE3F;
$category-hair: #2E716A;
$category-beauty: #4A963D;
$category-recepies: #303626;

$menu-break: xl;